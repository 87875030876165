/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from '../pages/home';
//import FlightList from '../pages/FlightList';
import Flightsearchresult from '../pages/Flight-search-result';
import Collecting from '../pages/collecting';
import About from '../pages/About'
import PaymentPage from '../pages/PaymentPage';
import Card from '../pages/card';
import Confirmation from '../pages/confirmation';
import AirlinesDetails from '../pages/airlinesDetails';
import DestinationDetails from '../pages/destinationDetails'
import GoogleMeta from '../MetaController/GoogleMeta';
import NotFoundPage from '../pages/404';
import OnlineCheckIn from '../pages/online-check-in';
import CheckIn from '../pages/CheckIn';
import CookieNotice from '../pages/CookieNotice';
import TaxesFees from '../pages/Taxes&Fees';
import PostTicketing from '../pages/PostTicketing';
import BaggageFee from '../pages/BaggageFee';
import OurServicesFee from '../pages/OurServicesFee';
import ContactUs from '../pages/ContactUs';
import TermsCondition from '../pages/termsCondition';
import Sitemap from '../pages/sitemap';
import DeepLink from '../MetaController/DeepLink';
import DealsDetails from '../pages/dealsDetails';
import CardMeta from '../pages/cardMeta';
import ThankYou from '../pages/ThankYou';
import Acknowledge from '../pages/Acknowledge';
import PaymentLink from '../pages/PaymentLink';
import HotelSearchbar from '../Shared/HotelSearchbar';
import CarSearchbar from '../Shared/CarSeachbar';
import CarEnquiry from '../pages/carEnquiry';
import HotelEnquiry from '../pages/hotelEnquiry';
import PaymentPageNMI from '../pages/PaymentPageNMI';
import PaymentFormNMI from '../pages/PaymentFormNMI';
import Cruise from '../pages/Cruise';
import Disclaimer from '../pages/Disclaimer';
import AirfareDeals from '../PPC/AirfareDeals'
import Southwest from '../PPC/Southwest';
import Aeromexico from '../PPC/Aeromexico';
import AirCanada from '../PPC/AirCanada';
import Alaska from '../PPC/Alaska';
import American from '../PPC/American';
import British from '../PPC/British';
import Copa from '../PPC/Copa';
import Emirates from '../PPC/Emirates';
import Etihad from '../PPC/Etihad';
import Frontier from '../PPC/Frontier';
import JetBlue from '../PPC/JetBlue';
import Latam from '../PPC/Latam';
import Lufthansa from '../PPC/Lufthansa';
import Qatar from '../PPC/Qatar';
import Spirit from '../PPC/Spirit';
import Turkish from '../PPC/Turkish';
import United from '../PPC/United';
import Viva from '../PPC/Viva';
import Volaris from '../PPC/Volaris'
import Privacy from '../pages/privacy';
import Amtrak from '../PPC/Amtrack';
//import Contact from '../pages/Contact'
//import About from '../pages/About'
// import flightResult from '../pages/flight-result'
// import FlightSearchResults from '../pages/flight-search-results'
// import about from '../pages/about'
//import Demo from '../pages/Demo'
// import ThankYou from '../pages/ThankYou'
// import SearchResultList from './../pages/SearchResultList'
// import TourDetails from './../pages/TourDetails'
// import Tours from './../pages/Tours'

const ScrollToTop = () => {
   const { pathname } = useLocation();
 
   useEffect(() => {
     window.scrollTo(0, 0);
   }, [pathname]);
 
   return null;
 };

const Router = ({ setHeaderColor }) => {
   // Define your list of URLs for the sitemap
  const urls = [
   { url: '/', changefreq: 'daily', priority: 1.0 },
   { url: '/about', changefreq: 'monthly', priority: 0.7 },
   // Add more URLs for other pages as needed
 ];

 // Simulate generating the sitemap within the component
 const generateSitemap = () => {
   const sitemapContent = `
     <?xml version="1.0" encoding="UTF-8"?>
     <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
       ${urls.map((url) => `
         <url>
           <loc>${url.url}</loc>
           <changefreq>${url.changefreq}</changefreq>
           <priority>${url.priority}</priority>
         </url>`).join('\n')}
     </urlset>
   `;

   // Log the generated sitemap content (for demonstration)
   //console.log(sitemapContent);
 };

 // Generate the sitemap when the component mounts
 useEffect(() => {
   generateSitemap();
 }, []);

 const location = useLocation();

 useEffect(() => {
  const loadGtag = (id, callback) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = gtag;

      gtag('js', new Date());
      gtag('config', id);

      if (callback) callback();
    };

    document.head.appendChild(script);
  };

  // Load all necessary gtag scripts
  loadGtag('G-4T4E0J0CXE');
  loadGtag('AW-10877497550', () => {
    window.gtag('config', 'AW-10877497550/0ShWCMaM1asZEM7h5cIo', {
      'phone_conversion_number': '(888) 415-1536'
    });
  });
  loadGtag('G-K0QR4DHC3F');

  // Conversion tracking function
  const gtag_report_conversion = (url) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-10877497550/r_CRCJeH1asZEM7h5cIo',
        'event_callback': callback
      });
    }
    return false;
  };

  // Add this function to global scope if you want to call it from other components
  window.gtag_report_conversion = gtag_report_conversion;

}, [location]);
 
   return (
      <>
      <ScrollToTop />
      
      <Routes>
         {/* <Route path='/*' element={<ScrollToTop />} /> */}
         <Route path='/home' element={<Navigate to='/'/>} />
         
         {/* <Route path='/flight-result' Component={flightResult} /> */}
         <Route exact path='/' element={<Home setHeaderColor={setHeaderColor('#fff')}/>} />
         <Route path='/Hotel' element={<HotelSearchbar setHeaderColor={setHeaderColor('#fff')}/>} />
         <Route path='/Car' element={<CarSearchbar setHeaderColor={setHeaderColor('#fff')}/>} />
         <Route path='/flight-List' element={<Flightsearchresult setHeaderColor={setHeaderColor('#fff')}/>}  />
         <Route path='/collecting' element={<Collecting />} />
         <Route exact path='/about' element={<About />} />
         <Route path='/payment/:searchID/:resultID' element={<PaymentPage/>} />
         <Route path='/card' element={<Card/>} />
         <Route path='/flights/payment/cmdetail/:bid' element={<CardMeta/>} />
         <Route path='/flights/payment/cchdetail/:bid' element={<PaymentLink />} />
         <Route path='/thankyou' element={<ThankYou/>} />
         <Route path='/acknowledged/review/:bid' element={<Acknowledge />} />
         <Route path='/confirmation/:searchID' element={<Confirmation/>} />
         <Route path='/airline/:airlineName' element={<AirlinesDetails />} />
         <Route path='/flights-to/:airlineName' element={<DestinationDetails />} />
         <Route path='/deals/:airlineName' element={<DealsDetails />} />
         <Route path='/flights/to/service' element={<GoogleMeta/>} />
         <Route path='/searchFlight/?' element={<DeepLink/>} />
         <Route exact path='/about' element={<About/>} />
         <Route exact path='/online-check-in' element={<CheckIn />} />
         <Route exact path='/taxes-and-fees' element={<TaxesFees />} />
         <Route exact path='/post-ticketing-fee' element={<PostTicketing />} />
         <Route exact path='/airline-baggage-fee' element={<BaggageFee />} />
         <Route exact path='/our-services-fee' element={<OurServicesFee />} />
         <Route exact path='/contact-us' element={<ContactUs />} />
         <Route exact path='/terms-and-condition' element={<TermsCondition />} />
         <Route exact path='/cruise' element={<Cruise />} />
         <Route path='/sitemap' element={<Sitemap/>} />
         <Route path='/car-enquiry' element={<CarEnquiry/>} />
         <Route path='/hotel-enquiry' element={<HotelEnquiry/>} />
         <Route path='/sitemap.xml' element="../../public/sitemap.xml" />
         <Route component={NotFoundPage} />
         <Route path='*' element={<NotFoundPage />} />
         <Route path='/not-found' element={<NotFoundPage />} />
         {/* NMI Payment Gateway */}
         <Route path='/nmi-payment' element={<PaymentFormNMI />} />
         <Route path='/disclaimer' element={<Disclaimer/>} />
         <Route path='/privacy-policy' element={<Privacy/>} />
         {/* This is PPC Routes */}
         {/* <Route path='/airfare-deals-tfn=1536&aname=Southwest-Airline&utm_source=cpc' element={<Southwest />} />
         <Route path='/airfare-deals-tfn=1536&aname=Aeromexico&utm_source=cpc' element={<Aeromexico />} />
         <Route path='/airfare-deals-tfn=1536&aname=Air-Canada&utm_source=cpc' element={<AirCanada />} />
         <Route path='/airfare-deals-tfn=1536&aname=Alaska&utm_source=cpc' element={<Alaska />} />
         <Route path='/airfare-deals-tfn=1536&aname=American-Airline&utm_source=cpc' element={<American />} />
         <Route path='/airfare-deals-tfn=1536&aname=British-Airways&utm_source=cpc' element={<British />} />
         <Route path='/airfare-deals-tfn=1536&aname=Copa&utm_source=cpc' element={<Copa />} />
         <Route path='/airfare-deals-tfn=1536&aname=Emirates&utm_source=cpc' element={<Emirates />} />
         <Route path='/airfare-deals-tfn=1536&aname=Etihad&utm_source=cpc' element={<Etihad />} />
         <Route path='/airfare-deals-tfn=1536&aname=Frontier&utm_source=cpc' element={<Frontier />} />
         <Route path='/airfare-deals-tfn=1536&aname=JetBlue&utm_source=cpc' element={<JetBlue />} />
         <Route path='/airfare-deals-tfn=1536&aname=Latam&utm_source=cpc' element={<Latam />} />
         <Route path='/airfare-deals-tfn=1536&aname=Lufthansa&utm_source=cpc' element={<Lufthansa />} />
         <Route path='/airfare-deals-tfn=1536&aname=Qatar&utm_source=cpc' element={<Qatar />} />
         <Route path='/airfare-deals-tfn=1536&aname=Spirit&utm_source=cpc' element={<Spirit />} />
         <Route path='/airfare-deals-tfn=1536&aname=Turkish&utm_source=cpc' element={<Turkish />} />
         <Route path='/airfare-deals-tfn=1536&aname=United-States-Airline&utm_source=cpc' element={<United />} />
         <Route path='/airfare-deals?tfn=1536&aname=Viva Aerobus&utm_source=cpc' element={<Viva />} /> */}
         {/* <Route path='/airfare-deals-tfn=1536&aname=Volaris&utm_source=cpc' element={<Volaris setHeaderColor={setHeaderColor('#fff')} />} /> */}
         <Route path='/airfare-deals' element={<AirfareDeals />} />
         <Route path='/train/reservation' element={<Amtrak />} />
         {/* <Route path='/collection' element={<Collecting />} /> */}
         {/* <Route path='/flight-List' Component={FlightSearchResults} /> */}
         {/* <Route exact path='/demo' element={<Demo/>} />
         <Route exact path='/contact' Component={Contact} />
          */}
         {/* <Route path='/tours' element={<Tours/>} />
         <Route path='/tours/:id' element={<TourDetails/>} />
         <Route path='/login' element={<Login/>} />
         <Route path='/register' element={<Register/>} />
         <Route path='/thank-you' element={<ThankYou/>} />
         <Route path='/tours/search' element={<SearchResultList/>} /> */}
        
      </Routes>
      </>
   )
}

export default Router